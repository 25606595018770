body {
  background-color: #282c34;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Assigner-center-container {
  text-align: center;
}

.Assigner-center {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Assigner-header {
  font-size: calc(10px + 2vmin);
}

.Assigner-link {
  color: #61dafb;
}

.Assigner-row-parent {
  text-align: center;
}

.Assigner-row-child {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.5rem;
}

.Assigner-align-left {
  text-align: left;
}

.Assigner-align-center {
  text-align: center;
}

.Assigner-align-right {
  text-align: right;
}

.Assigner-no-padding {
  padding: 0;
}

.Assigner-no-margin {
  margin: 0;
}

.Assigner-list-type-none {
  list-style-type: none;
}

.Assigner-font-small {
  font-size: small;
}

.Assigner-font-medium {
  font-size: medium;
}

.Assigner-font-large {
  font-size: large;
}

.Assigner-font-xlarge {
  font-size: x-large;
}


.Toastify__toast-body {
  font-size: large;
}